<template>
  <section class="invoice-preview-wrapper">
    <div
      v-for="(piece, index) in data.pieces"
      id="container"
      :key="piece.id"
    >
      <div class="table">
        <div class="header-of-page">
          <div class="first">
            <logo style="width: 100px" />
          </div>
          <div class="second">
            <ShipmentBarCode
              :value="data.awb"
              :height="40"
              :width="2.5"
            />
          </div>
        </div>
        <div class="reference">
          Reference: <strong>{{ data.ref_no }}</strong>
        </div>
        <div class="first-main-div">
          <div class="add-border-right">
            <div class="rotate-it">
              <p>Shipment Details</p>
            </div>
          </div>
          <div class="contain-all-first-div-right">
            <div class="first-div-right">
              <div class="item two">
                <p>Origin:</p>
                <p class="bold-p">
                  {{ data.client.city.country.code }}
                  <!--                  {{ data.client.city.name.en }} - {{ data.client.city.country.name.en }}-->
                </p>
              </div>
              <div class="item two">
                <p>Destination:</p>
                <p class="bold-p">
                  {{ data.city.country.code }}
                  <!--                  {{ data.city.name.en }} - {{ data.city.country.name.en }}-->
                </p>
              </div>
              <div class="item four">
                <p>Payment Type:</p>
                <p class="bold-p">
                  {{ data.charge_type }}
                </p>
              </div>
            </div>
            <div class="second-div-right">
              <div class="item four">
                <p>Weight: <b>{{ data.pieces_weights[index] | roundDecimal }} KG</b></p>
                <p>Chargeable: <b>{{ data.pieces_weights[index] | roundDecimal }} KG</b></p>
              </div>
              <div class="item four">
                <p>COD:</p>
                <p class="bold-p-first-contain">
                  {{ data.cod_amount }}{{ data.cod_amount ? '$' : '' }}
                </p>
              </div>
              <div class="item two">
                <p class="bold-p-first-contain-2">
                  PCS:
                </p>
                <p class="bold-p-first-contain-2">
                  {{ index + 1 + '/' + data.pieces.length }}
                </p>
              </div>
            </div>
            <div class="third-div-right">
              <!--              <div class="item two items">
                <p>Customize value:</p>
                <p>183.6 USD</p>
              </div>
              <div class="item two items">
                <p>Good Origin:</p>
              </div>-->
              <div class="item ten">
                <p>Description:</p>
                <p>{{ data.description[index] }}</p>
              </div>
              <div class="item two">
                <div class="here auto auto-2">
                  <div class="first-div-here this-here">
                    <p>Service Type</p>
                    <p class="bold-p-2 font-small-3">
                      {{ data.service_type.name.en }}
                    </p>
                  </div>
                  <div class="second-div-here">
                    <span class="font-small-4">Pickup Date</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="first-main-div add-default-height">
          <div class="add-border-right">
            <div class="rotate-it">
              <p>Shipment Details</p>
            </div>
          </div>
          <div class="contain-all-first-div-right">
            <div class="large-div-right">
              <div class="item two-special-large">
                <span style="padding-right: 10rem">Account: <strong>{{ data.client.client_no }}</strong></span>
                <p class="bold-p-2">
                  {{ data.client.addresses ? data.client.addresses[0].address : '' }}
                </p>
                <p class="bold-p-2">
                  {{ data.client.addresses ? data.client.addresses[0].address : '' }},
                </p>
                <div class="special-text-large">
                  <div style="padding-top: 20px">
                    <p class="bold-p-2">
                      {{ data.client.city.title }}
                    </p>
                    <p class="bold-p-2">
                      {{ data.client.city.country.title }}
                    </p>
                  </div>
                  <div>
                    <span>tel: <strong>{{ data.client ? data.client.phone : '' }},
                      {{ data.client ? data.client.mobile : '' }}</strong></span>
                  </div>
                </div>
              </div>
              <div class="here auto auto-2">
                <div class="first-div-here this-here">
                  <p>Service Type</p>
                  <p class="bold-p-2">
                    {{ data.service_type.name.en }}
                  </p>
                </div>
                <div class="second-div-here">
                  <span>Pickup Date:</span>
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <div class="first-main-div add-default-height">
          <div class="add-border-right">
            <div class="rotate-it">
              <p>Shipment Details</p>
            </div>
          </div>
          <div class="contain-all-first-div-right">
            <div class="large-div-right">
              <div class="item two-special-large">
                <div class="section-title">
                  Shipper
                </div>
                <span>Account: <strong>{{ data.client.client_no }}</strong></span>
                <p class="bold-p-2">
                  {{ data.client.addresses ? data.client.addresses[0].address : '' }}
                </p>
                <div>
                  <p class="bold-p-2">
                    {{ data.client.city.title }}, {{ data.client.city.country.title }}
                  </p>
                </div>
                <div>
                  <p>tel: <strong>{{ data.client ? data.client.phone : '' }},
                    {{ data.client ? data.client.mobile : '' }}</strong></p>
                </div>
              </div>
              <div class="item two-special-large">
                <div class="section-title">
                  Consignee
                </div>
                <p class="bold-p-2">
                  {{ data.consignee_name ? data.consignee_name : (data.consignee ? data.consignee.name : '') }}
                </p>
                <p class="bold-p-2">
                  {{ data.address ? data.address : data.consignee ? data.consignee.addresses[0].name : '' }}
                </p>
                <div>
                  <p class="bold-p-2">
                    {{ data.city ? data.city.title : '' }}, {{ data.city ? data.city.country.title : '' }}
                  </p>
                </div>
                <div>
                  <span>tel: <strong>{{ data.phone1 }}{{ data.phone2 ? (', ' + data.phone2) : '' }}</strong></span>
                </div>
              </div>
              <div class=" rotate-image here auto auto-2">
                <ShipmentBarCode
                  :value="data.awb"
                  :height="70"
                  :width="2"
                />
              </div>
            </div>
          </div>
        </div>
        <p style="padding:10px 0 0 10px">
          Remarks: {{ data.remarks }}
        </p>
        <p style="padding:10px 0 10px 10px">
          Signature:
        </p>

      </div>
      <div class="page-break" />
    </div>
  </section>
</template>

<script>
import { VBToggle } from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import ShipmentBarCode from '@/layouts/components/ShipmentBarCode.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    Logo,
    ShipmentBarCode,
  },
  props: ['data'],
  data() {
    return {
      loading: true,
      fields: ['description_of_goods', 'country_of_origin', 'weight'],
      shipmentBarWidth: 100,
    }
  },
  created() {

  },
  methods: {
    printInvoice() {
      window.print()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media all {
  .page-break {
    display: none;
  }
}

#container {
  width: 600px;
  //margin: auto;
  margin-top: 2rem;
  padding-inline: 1.5rem;
  background-color: #fff;
}

.table {
  border: 3px solid black;
  margin: auto;
}

.header-of-page {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.reference {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  padding-inline-start: 1rem;
}

.header-of-page .first {
  width: 30%;
}

.header-of-page .second {
  width: 60%;
}

.first-main-div {
  display: flex;
  border-bottom: 2px solid black;
}

.first-div-right,
.second-div-right,
.third-div-right,
.large-div-right {
  display: flex;
}

.large-div-right span {
  font-size: medium;
}

.large-div-right div {
  font-size: medium;
}

.contain-all-first-div-right {
  width: 100%;
}

.four {
  flex: 4.6;
}

.ten {
  flex: 10.6;
}

.two {
  flex: 3;
  border-right: 2px solid black;
}

.two-special-large {
  flex: 4.28;
  border-right: 2px solid black;
}

.two-special-large .section-title {
  border-bottom: 2px solid black;
  width: 104%;
  margin-left: -3px;
  text-align: center;
}

/*.one {*/
/*    flex: 1;*/
/*}*/

.item {
  padding: 0.3em;
  width: 55px;
  min-width: 0;
  color: black;
  font-family: monospace;
  font-size: 13px;
}

strong {
  font-size: 15px;
}

.reference {
  font-size: 10px;
}

.initial {
  flex: initial;
}

.auto {
  flex: auto;
}

p {
  margin: 0;
  font-size: 12.5px;
}

.bold-p {
  font-weight: bold;
  font-size: 20px;
}

.bold-p-2 {
  font-size: 16px;
  font-weight: 800;
}

.add-border-right {
  border-right: 2px solid black;
}

.first-div-right {
  border-bottom: 2px solid black;
}

.second-div-right .two {
  border-right: 0 !important;
  flex: 2.2
}

.second-div-right .four {
  border-right: 2px solid black;
}

.bold-p-first-contain {
  font-size: 15px;
  font-weight: bold;
}

.bold-p-first-contain-2 {
  font-size: 18px;
  font-weight: bold;
  word-break: break-all;
}

.add-border-right {
  width: 5%
}

.rotate-it {
  display: flex;
  transform: rotate(270deg);
  position: relative;
  top: 2.5rem;
  left: 0;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-content: center;
  align-items: center;
  font-weight: 800;
}

.rotate-it p {
  font-size: 13px;
  white-space: nowrap;
}

.third-div-right {
  border-top: 2px solid black;
}

.third-div-right .two {
  border-right: 0;
  flex: 2.5
}

.third-div-right .ten {
  border-right: 2px solid black;
}

.special-text-large {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

/*.add-default-height {*/
/*    min-height: 220px;*/
/*}*/
span {
  font-size: 14px;
}

.here .this-here {
  border-bottom: 2px solid black;
  text-align: center;
  padding: 15px 0 37px 0;
  word-break: break-all;
  width:104px;
  margin-left: -3px;
}

.auto-2 {
  width: 57px;
}

.second-div-here {
  text-align: center;
  padding-top: 20px;
  width: max-content;
}

.rotate-image {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  transform: rotate(270deg);
}

.rotate-image svg {
  height: 60px;
}

@media print {
  @page {
    size: 11cm 16.3cm;
    margin: 0;
  }
  .invoice-actions {
    display: none;
  }
  .page-break {
    display: block;
    page-break-before: always;
    :last-child {
      page-break-after: auto;
    }
  }
}

</style>
